.brandUsageLabel{
  margin: 0.5em auto 0.5em;
}

.headings{
  font-weight: 500;
}

.brandLogo{
  align-items: center;
  object-fit: contain;
  display: block;
  padding: 10px;
  width: 100%;
  max-height: 108px;
}

.noImage{
  background-color: lightgray;
  width: 220px;
  height: 113px;
}

.brandName{
  float: left;
  font-weight: 700;
  text-overflow: ellipsis;
  overflow : hidden;
  white-space: nowrap; 
}


.dropdown > .btn {
  padding: 10px;
  padding-bottom: 8px;
}